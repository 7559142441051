/*
Expector's personal homepage  Copyright (C) 2022  Expector-Hutch
This program comes with ABSOLUTELY NO WARRANTY; for details type `show w'.
This is free software, and you are welcome to redistribute it
under certain conditions; type `show c' for details.
*/

@import url(/node_modules/aplayer/dist/APlayer.min.css);

.aplayer {
    background: #242424;
    .aplayer-lrc {
        &:before {
            background: linear-gradient(180deg, #242424 0, hsla(0, 0%, 100%, 0));
        }
        &:after {
            background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #242424);
        }
        p {
            color: white;
            &.aplayer-lrc-current {
                color: #66ccff;
            }
        }
    }
    .aplayer-info {
        .aplayer-music {
            .aplayer-author {
                color: #66ccff;
            }
        }
    }
}
.aplayer-title {
    color: white;
}